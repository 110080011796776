import React, { PropsWithChildren, useCallback } from 'react';
import useAsync from 'src/hooks/useAsync';
import { loginWebBroker } from 'src/services/api/auth';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomError from 'src/pages/sendout/Error';
import { useCallBackUrl } from 'src/pages/sendout/utils';

function SendoutAuth({ children }: PropsWithChildren<{}>) {
  const location = useLocation();
  const { callBackUrl, assignmentNumber } = useCallBackUrl();

  const loginWebBrokerRequest = useCallback(async () => {
    await loginWebBroker(callBackUrl || '', assignmentNumber || '');
  }, [location.search]);

  const {
    data,
    error,
    isLoading: loading,
  } = useAsync({
    fn: loginWebBrokerRequest,
    immediate: true,
  });

  if (error) {
    return <CustomError error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
}

export default SendoutAuth;
