import React, { ReactElement } from 'react';
import { Outlet } from 'react-router';
import PublicLayout from 'src/layouts/PublicLayout';
import SendoutAuth from 'src/pages/sendout/Auth';

const SendoutRoot = (): ReactElement => {
  return (
    <PublicLayout>
      <SendoutAuth>
        <Outlet />
      </SendoutAuth>
    </PublicLayout>
  );
};

export default SendoutRoot;
