import React from 'react';
import { Error } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
  },
  warningIcon: {
    fontSize: '2.1875rem',
    color: theme.palette.warning.main,
    marginRight: theme.spacing(2),
  },
  warningText: {
    fontSize: '20px',
    color: theme.palette.error.main,
  },
}));

function CustomError({ error }: { error: any }) {
  const { classes } = useStyles();

  return (
    <div className={classes.errorContainer}>
      <Error className={classes.warningIcon} />
      <Typography className={classes.warningText} variant="subtitle2">
        {error?.message || error?.callbackUrl || 'Something wrong'}
      </Typography>
    </div>
  );
}

export default CustomError;
