import React, { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import { toast as showToast } from 'react-toastify';

const ErrorToastWithButton: FunctionComponent<{
  message: any;
  requestId: string;
}> = ({ message, requestId }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <h2
        style={{
          fontSize: '16px',
          marginBottom: '10px',
        }}
      >
        ERROR
      </h2>
      <div style={{ marginBottom: '10px' }}>{message}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {requestId && (
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => {
              navigator.clipboard
                .writeText(requestId)
                .then((r) => {
                  showToast.success('Request ID copied to clipboard');
                })
                .catch((e) => {
                  showToast.error('Error copying request ID to clipboard: ', e);
                });
            }}
            style={{
              background: 'white',
              height: '30px',
              fontSize: '10px',
            }}
          >
            COPY REQUEST ID
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorToastWithButton;
