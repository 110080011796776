import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles()((theme: Theme) => ({
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '50vw',
    margin: 'auto',
    gap: '30px',
    justifyContent: 'center',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '30px',
    padding: '8px 12px',
    backgroundColor: 'unset',
    width: '100%',
    boxShadow: '0px 0px 14px -4px black',
    '& input': {
      backgroundColor: 'unset',
      border: 'none',
      width: '100%',
      marginLeft: '8px',
      marginRight: '8px',
      padding: '4px',
    },
    '& input:focus': {
      outline: 'none',
    },
    '&:hover': {
      boxShadow: '0px 0px 5px -1px black',
      transition: 'box-shadow .2',
    },
  },
  black: {
    color: '#000',
  },
  white: {
    color: '#fff',
  },
}));

const SearchBar = ({
  onSearch,
  placeholder = '',
  color = 'black',
  btnOptions,
  onChange,
}: {
  onSearch: (search: { [key: string]: string }) => void;
  placeholder?: string;
  color?: 'white' | 'black';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  btnOptions?: { key: string; label: string }[];
}) => {
  const { classes } = useStyles();
  const [searchValue, setSearchValue] = useState<string>(
    new URLSearchParams(location.search).get('fullSearch') || '',
  );
  const enterKeyChecker = ({ key }: any) => {
    if (key === 'Enter') {
      onSearch({ address: searchValue });
    }
  };
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target?.value);
    onChange && onChange(event);
  };
  return (
    <div className={classes.searchWrapper}>
      <div className={classes.wrapper + ' ' + classes[color]}>
        <SearchIcon onClick={() => onSearch({ address: searchValue })} />
        <input
          className={classes[color]}
          onChange={onChangeValue}
          onKeyDown={enterKeyChecker}
          value={searchValue}
          placeholder={placeholder}
        />
      </div>
      {btnOptions &&
        btnOptions.map(({ key, label }) => (
          <Button
            variant="contained"
            key={key}
            onClick={() => onSearch({ key: searchValue })}
          >
            {label}
          </Button>
        ))}
    </div>
  );
};
export default SearchBar;
