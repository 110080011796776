import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

export const COMMON_NAMESPACE = 'common';
export const PROTOCOL_NAMESPACE = 'protocol';
export const SETTLEMENT_NAMESPACE = 'settlement';
export const DYNAMIC_NAMESPACE = 'dynamic';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // eslint-disable-next-line no-undef
    debug: process.env.NODE_ENV !== 'production',
  });

export default i18n;
