import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import theme from 'src/theme/adminTheme';
import AuthProvider from 'src/pages/AuthProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/*@ts-ignore OLDLIB */}
        <AuthProvider>
          <Outlet />
          <ToastContainer pauseOnFocusLoss={false} />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
