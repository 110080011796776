import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
// @ts-ignore
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  Badge,
  Link,
  Theme,
} from '@mui/material';
import { AuthContext } from 'src/pages/AuthProvider';
import { User } from 'src/types/user';
import NavItem from 'src/layouts/DashboardLayout/NavItem';
import navConfig, {
  NavConfig,
  NavItem as NavItemType,
} from 'src/layouts/DashboardLayout/navConfig';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
}));

const renderNavItems = (
  user: User,
  config: NavConfig,
  pathname: string,
  depth?: number,
) => {
  if (!user || !(config.roles || []).includes(user.role)) {
    return null;
  }

  return (
    <List key={config.subheader}>
      {config.subheader && (
        <ListSubheader disableSticky>{config.subheader}</ListSubheader>
      )}
      {config.items
        .filter((item) => {
          if (item.roles) {
            return item.roles.includes(user.role);
          }

          return true;
        })
        .reduce(
          (acc: ReactElement[], item: NavItemType) =>
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            reduceChildRoutes(user, acc, pathname, item, depth),
          [],
        )}
    </List>
  );
};

const reduceChildRoutes = (
  user: User,
  acc: ReactElement[],
  pathname: string,
  item: NavItemType,
  depth = 0,
) => {
  if (item.items) {
    const open = item.items.reduce((result: boolean, subItem: NavItemType) => {
      if (pathname.match(`${subItem.href}`)) {
        return true;
      }

      return result;
    }, false);

    const navItems = renderNavItems(
      user,
      {
        items: item.items,
        roles: item.roles,
      },
      pathname,
      depth + 1,
    );

    if (navItems) {
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.title}
          open={Boolean(open)}
          title={item.title}
        >
          {navItems}
        </NavItem>,
      );
    }
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        title={item.title}
      />,
    );
  }

  return acc;
};

interface Props {
  toggleMenu: () => void;
  isMenuOpen: boolean;
}

const NavBar: React.FunctionComponent<Props> = ({ toggleMenu, isMenuOpen }) => {
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      toggleMenu();
    }
  }, [location.pathname]);

  const content = useMemo(
    () => (
      <div className={classes.root}>
        <nav className={classes.navigation}>
          {navConfig.map((config) =>
            renderNavItems(user as User, config, location?.pathname),
          )}
        </nav>
        <Divider />
        <div className={classes.profile}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={{
              dot: classes.badgeDot,
            }}
            variant="dot"
          >
            <Avatar alt="Person" className={classes.avatar} src={''} />
          </Badge>
          <div className={classes.details}>
            <Link
              component={RouterLink}
              to="/profile/1/timeline"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user && user.email}
            </Link>
            <Typography variant="body2">{user && user.role}</Typography>
          </div>
        </div>
      </div>
    ),
    [],
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={toggleMenu}
          open={isMenuOpen}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
