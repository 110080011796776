const AUTH_USER_TOKEN_KEY = 'USER_AUTH_TOKEN';

export const getToken = (): string | null => {
  return localStorage.getItem(AUTH_USER_TOKEN_KEY);
};

export const setToken = (token: string): any => {
  return localStorage.setItem(AUTH_USER_TOKEN_KEY, token);
};

export const removeToken = (): any => {
  return localStorage.removeItem(AUTH_USER_TOKEN_KEY);
};
