import React, { lazy } from 'react';
import { Navigate } from 'react-router';

const leadsFlowRoutes = [
  {
    path: '/leads-flow/:hash/:page?/:roleKey?',
    Component: lazy(() => import('src/pages/PublicHandover')),
    isPublic: true,
    children: [
      {
        index: true,
        isPublic: true,
        Component: lazy(() => import('src/routes/helpers/LeadsFlowHelper')),
      },
      // {
      //   path: '/leads-flow/:hash/:page(home)',
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Home')),
      // },
      // {
      //   path: '/leads-flow/:hash/:page(done)',
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Done')),
      // },
      // {
      //   path: `/leads-flow/:hash/:page(${LeadsFlowQuestionName.PARTICIPANTS})/:participantId?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/leads-flow/:hash/:page(init|${Object.values(
      //     LeadsFlowQuestionName,
      //   ).join('|')})`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      {
        Component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default leadsFlowRoutes;
