import { useLocation } from 'react-router-dom';

export function useCallBackUrl() {
  const location = useLocation();

  const callBackUrl =
    new URLSearchParams(location.search).get('callbackurl') || '';

  const assignmentNumber =
    new URL(callBackUrl).searchParams.get('id_oppdrag') || '';

  return {
    callBackUrl,
    assignmentNumber,
  };
}
