import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { getToken } from 'src/services/authToken';
import ValidationError from 'src/services/api/errors/ValidationError';
import ApiError from 'src/services/api/errors/ApiError';

const handleError = (e: any): AxiosError => {
  if (e.response) {
    if (e.response.status === 422 && e.response.data) {
      throw new ValidationError(
        e.response.data.errors,
        e.response.headers['x-request-id'],
      );
    }
  }
  if (e?.response?.status) {
    throw new ApiError(
      e.response?.data?.message || e.response.statusText,
      e.response.headers['x-request-id'],
    );
  }

  throw e;
};

const addAuthHeader = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authToken = getToken();

  if (!authToken) {
    return config;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    },
  };
};

class ApiService {
  private axiosInstance: AxiosInstance;
  private static instance: ApiService;

  constructor() {
    const params = {
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {},
    };

    this.axiosInstance = axios.create(params);
  }

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }

    return ApiService.instance;
  }

  async get(url: string, config: AxiosRequestConfig = {}): Promise<any> {
    try {
      const { data } = await this.axiosInstance.get(url, addAuthHeader(config));
      return data;
    } catch (e) {
      handleError(e);
    }
  }

  async post(
    url: string,
    data?: {
      [K in any]: any;
    },
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      if (
        config.headers &&
        config.headers['Content-Type'] === 'multipart/form-data' &&
        !(data instanceof FormData)
      ) {
        const form = new FormData();
        for (const key in data) {
          form.append(key, data[key]);
        }
        const { data: responseData } = await this.axiosInstance.post(
          url,
          form,
          addAuthHeader(config),
        );
        return responseData;
      }
      const { data: responseData } = await this.axiosInstance.post(
        url,
        data,
        addAuthHeader(config),
      );

      return responseData;
    } catch (e) {
      handleError(e);
    }
  }

  async delete(url: string, config: AxiosRequestConfig = {}): Promise<any> {
    try {
      const { data } = await this.axiosInstance.delete(
        url,
        addAuthHeader(config),
      );
      return data;
    } catch (e) {
      handleError(e);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch(
    url: string,
    data?: any,
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      if (
        config.headers &&
        config.headers['Content-Type'] === 'multipart/form-data' &&
        !(data instanceof FormData)
      ) {
        const form = new FormData();
        for (const key in data) {
          form.append(key, data[key]);
        }
        const { data: responseData } = await this.axiosInstance.patch(
          url,
          form,
          addAuthHeader(config),
        );
        return responseData;
      }

      const { data: responseData } = await this.axiosInstance.patch(
        url,
        data,
        addAuthHeader(config),
      );
      return responseData;
    } catch (e) {
      handleError(e);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put(
    url: string,
    data?: any,
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      if (
        config.headers &&
        config.headers['Content-Type'] === 'multipart/form-data' &&
        !(data instanceof FormData)
      ) {
        const form = new FormData();
        for (const key in data) {
          form.append(key, data[key]);
        }
        const { data: responseData } = await this.axiosInstance.put(
          url,
          form,
          addAuthHeader(config),
        );
        return responseData;
      }

      const { data: responseData } = await this.axiosInstance.put(
        url,
        data,
        addAuthHeader(config),
      );
      return responseData;
    } catch (e) {
      handleError(e);
    }
  }
}

export default ApiService;
