import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/i18n';
import routes from 'src/routes';
import buffer from 'buffer';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';

(window as any).global = window;
// @ts-ignore
window.Buffer = window.Buffer || buffer.Buffer;

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

const Main = () => useRoutes(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <CacheProvider value={muiCache}>
    <TssCacheProvider value={tssCache}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </TssCacheProvider>
  </CacheProvider>,
);
