import React, { ReactElement, Suspense } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { LinearProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

//interface StyleRules {};

const useStyles = makeStyles()((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[300],
    },
  }),
);

const Layout = (props: any): ReactElement => {
  const { classes }: any = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main>
        <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
      </main>
    </div>
  );
};

export default Layout;
