import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router';

const protocolRoutes = [
  {
    path: '/protocol/:hash/:page?/:roleKey?/:participantId?',
    Component: lazy(() => import('src/pages/PublicHandover')),
    isPublic: true,
    children: [
      {
        index: true,
        Component: lazy(() => import('src/routes/helpers/ProtocolHelper')),
      },
      // {
      //   path: '/protocol/:hash/:page(home)',
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Home')),
      // },
      // {
      //   path: '/protocol/:hash/:page(done)',
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Done')),
      // },
      // {
      //   path: `/protocol/:hash/:page(${ProtocolQuestionName.PARTICIPANTS})/:participantId?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/protocol/:hash/:page(${ProtocolQuestionName.KEYS_REMAX_AT})/:number?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/protocol/:hash/:page(${ProtocolQuestionName.SIGNATURE}|${ProtocolQuestionName.BUYER_SIGNATURE}|${ProtocolQuestionName.SELLER_SIGNATURE})/:roleKey?/:participantId?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/protocol/:hash/:page(init|${Object.values(
      //     ProtocolQuestionName,
      //   ).join('|')})`,
      //   isPublic: true,
      //   Component: lazy(
      //     () => import('src/pages/PublicHandover/component/PageContainer'),
      //   ),
      // },
      {
        Component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default protocolRoutes;
