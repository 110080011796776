import React, { lazy } from 'react';
import { Navigate } from 'react-router';
import { UserRole } from 'src/types/user';

const adminRoutes = [
  {
    path: '/admin',
    Component: lazy(() => import('src/pages/dashboard')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/set-new-password',
    Component: lazy(() => import('src/pages/SetNewPassword')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/handovers',
    Component: lazy(() => import('src/pages/handover/list')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/handovers/:id/:mainTab/:tab',
    Component: lazy(() => import('src/pages/handover/item')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/leads-flow',
    Component: lazy(() => import('src/pages/leadsFlow/list')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },

  {
    path: '/admin/protocol/:hash/:step',
    Component: lazy(() => import('src/pages/handover/FromPublicLink')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/agencies',
    Component: lazy(() => import('src/pages/agency/List')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/agencies/:id',
    Component: lazy(() => import('src/pages/agency/Item')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/agencies/:id/edit',
    Component: lazy(() => import('src/pages/agency/Item')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/handoverProviders',
    Component: lazy(() => import('src/pages/handoverProvider/List')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/handoverProviders/:id',
    Component: lazy(() => import('src/pages/handoverProvider/Item')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/protocol-config',
    Component: lazy(() => import('src/pages/ProtocolConfig/ProtocolConfig')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/protocol-config/edit',
    Component: lazy(
      () => import('src/pages/ProtocolConfig/EditProtocolConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/protocol-config/:configId',
    Component: lazy(
      () => import('src/pages/ProtocolConfig/ProtocolConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/settlement-config',
    Component: lazy(
      () => import('src/pages/SettlementConfig/SettlementConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/settlement-config/edit',
    Component: lazy(
      () => import('src/pages/SettlementConfig/EditSettlementConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/settlement-config/:configId',
    Component: lazy(
      () => import('src/pages/SettlementConfig/SettlementConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/leads-flow-config',
    Component: lazy(() => import('src/pages/LeadsFlowConfig/LeadsFlowConfig')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/leads-flow-config/edit',
    Component: lazy(
      () => import('src/pages/LeadsFlowConfig/EditLeadsFlowConfig'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/service-providers/:id',
    Component: lazy(() => import('src/pages/ServiceProviders/Editor')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/service-providers',
    Component: lazy(() => import('src/pages/ServiceProviders/index')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/protocol-config/:configId/step/:questionId`,
    Component: lazy(
      () => import('src/pages/ProtocolConfig/EditProtocolConfigStep'),
    ),
    // exact: true,
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/:configType/:configId/notification-channel/:notificationChannelKey`,
    Component: lazy(() => import('src/pages/NotificationTexts')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/settlement-config/:questionId/config/:configId`,
    Component: lazy(
      () => import('src/pages/SettlementConfig/EditSettlementConfigStep'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/leads-flow-config/:configId/step/:questionId`,
    Component: lazy(
      // This is correct, it should be EditProtocolConfigStep and not EditLeadsFlowConfigStep
      // The EditLeadsFlowConfigStep has never been used to update the locales for a specific step in leadsFlowConfig
      // If you change this you need to make sure that the EditLeadsFlowConfigStep can handle the locale-updating like
      // ... EditProtocolConfigStep does.
      () => import('src/pages/ProtocolConfig/EditProtocolConfigStep'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/leads-flow-config/:configId/marketplace-content`,
    Component: lazy(
      () => import('src/pages/LeadsFlowConfig/MarketplaceContent'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: `/admin/protocol-config/:configId/marketplace-content`,
    Component: lazy(
      () => import('src/pages/LeadsFlowConfig/MarketplaceContent'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/users',
    Component: lazy(() => import('src/pages/users/List')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
  },
  {
    path: '/admin/quicksight',
    Component: lazy(() => import('src/pages/Quicksight')),
    // exact: true,
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/notification-texts',
    Component: lazy(() => import('src/pages/NotificationTexts')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/popular-categories',
    Component: lazy(() => import('src/pages/PopularCategories')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/sub-roles',
    Component: lazy(() => import('src/pages/SubRoles')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/general',
    Component: lazy(() => import('src/pages/General')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/rates',
    Component: lazy(() => import('src/pages/Rates')),
    //exact: 'true',
    roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT],
  },
  {
    path: '/admin/region-info',
    Component: lazy(() => import('src/pages/RegionInfo')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/pricing-area',
    Component: lazy(() => import('src/pages/PricingAreas')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/countries',
    Component: lazy(() => import('src/pages/Countries')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/reports-leads',
    Component: lazy(() => import('src/pages/reports/Leads/Leads')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/reports-lead-error-follow-ups',
    Component: lazy(
      () => import('src/pages/reports/LeadErrorFollowUps/LeadErrorFollowUps'),
    ),
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/reports-sync-queue',
    Component: lazy(() => import('src/pages/reports/SyncQueue')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/reports-leads/:id',
    Component: lazy(() => import('src/pages/reports/LeadsEditor')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/reports-logs',
    Component: lazy(() => import('src/pages/reports/Logs')),
    //exact: 'true',
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
      UserRole.PROVIDER_EDIT,
    ],
  },
  {
    path: '/admin/notifications',
    Component: lazy(
      () => import('src/pages/reports/Notifications/Notifications'),
    ),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    path: '/admin/reports-sync/:date?',
    Component: lazy(() => import('src/pages/reports/SyncHandovers')),
    //exact: 'true',
    roles: [UserRole.ADMIN],
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default adminRoutes;
