import { User } from 'src/types/user';
import { setToken, removeToken } from 'src/services/authToken';
import ApiService from 'src/services/api/ApiService';

const api = ApiService.getInstance();

export const login = async (email: string, password: string) => {
  const { token } = await api.post('/login', { email, password });

  if (token) {
    setToken(token);
  }
};
export const changePassword = async (email: string) => {
  const { token } = await api.post('/changePassword', { email });

  if (token) {
    setToken(token);
  }
};

export const resetPassword = async (
  password: string,
  passwordConfirmation: string,
  token?: string,
) => {
  if (token) {
    return await api.post('/resetPassword', {
      password,
      passwordConfirmation,
      token,
    });
  }
};

export const setNewPassword = async (
  password: string,
  passwordConfirmation: string,
  userId: string,
) => {
  return await api.post('/setNewPassword', {
    password,
    passwordConfirmation,
    id: userId,
  });
};

export const loginWebBroker = async (
  url: string,
  assignmentNumber?: string,
) => {
  const { token } = await api.post('/loginWebBroker', {
    callbackUrl: url,
    assignmentNumber,
  });

  if (token) {
    setToken(token);
  }
};

export const logout = async () => {
  removeToken();
};

export const getCurrentUser = async (): Promise<User> => {
  const { user } = await api.get('/user/current');
  return user;
};
