import palette from 'src/theme/adminTheme/palette';

export default {
  root: {},
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary,
    },
  },
};
