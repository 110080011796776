import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router';
import { UserRole } from 'src/types/user';

const publicRoutes = [
  {
    path: '/',
    Component: () => <Navigate to="/admin" />,
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_EDIT,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_VIEW,
    ],
  },
  {
    path: '/login',
    Component: lazy(() => import('src/pages/auth/Login')),
    isPublic: true,
    publicOnly: true,
  },
  {
    path: '/404',
    Component: lazy(() => import('src/pages/notFound')),
    isPublic: true,
  },
  {
    path: '/forgot-password',
    Component: lazy(() => import('src/pages/auth/ForgotPassword')),
    isPublic: true,
  },
  {
    path: '/reset-password',
    Component: lazy(() => import('src/pages/auth/ResetPassword')),
    isPublic: true,
  },
  {
    path: '/check-email',
    Component: lazy(() => import('src/pages/auth/CheckEmail')),
    isPublic: true,
  },
  {
    path: '/api-doc',
    Component: lazy(() => import('src/pages/Swagger')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default publicRoutes;
