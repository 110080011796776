import React, { Suspense, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { LinearProgress, Theme } from '@mui/material';
import NavBar from 'src/layouts/DashboardLayout/NavBar';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import { useLocation } from 'react-router-dom';
import 'src/pages/handover/ManualHandoverCreate/styles.css';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    //minHeight: '100vh', //TODO check the style
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

const Dashboard: React.FunctionComponent = ({ children }: any) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const [isMenuOpen, changeIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(
    () => changeIsMenuOpen(!isMenuOpen),
    [isMenuOpen],
  );

  return (
    <>
      <TopBar toggleMenu={toggleMenu} />
      {pathname === '/admin/set-new-password' ? null : (
        <NavBar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      )}

      <div className={classes.container}>
        <div className={`${classes.content}`}>
          <Suspense fallback={<LinearProgress />}>{children}</Suspense>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
