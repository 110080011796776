import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useNavigate,
  useLocation,
  matchRoutes,
  Navigate,
} from 'react-router-dom';
import { getCurrentUser, logout as authLogout } from 'src/services/api/auth';
import routes from 'src/routes';
import { User, UserRole } from 'src/types/user';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getToken, removeToken } from 'src/services/authToken';
import useAsync from 'src/hooks/useAsync';

const useStyles = makeStyles()({
  loaderRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface AuthProviderValues {
  user: User | null;
  logout: () => void;
  isLogoutProcessing: boolean;
  isEditor: boolean;
  isAdmin: boolean;
  isBroker: boolean;
  isLeadsFlowEditor: boolean;
}

const init: AuthProviderValues = {
  user: null,
  logout: () => {
    console.log('Default logout');
  },
  isLogoutProcessing: false,
  isEditor: false,
  isAdmin: false,
  isBroker: false,
  isLeadsFlowEditor: false,
};

export const AuthContext: React.Context<AuthProviderValues> =
  React.createContext(init);

const AuthProvider: React.FunctionComponent = ({ children }: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const match: any = matchRoutes(routes, location.pathname);
  const { route } = match[match.length - 1];
  const [user, changeUser] = useState<User | null>(null);

  const onLogoutSuccess = useCallback(() => {
    changeUser(null);
    navigate('/login');
  }, [navigate]);

  const { execute: logout, isLoading: isLogoutProcessing } = useAsync({
    fn: authLogout,
    options: { successCb: onLogoutSuccess },
  });

  const checkRoles = useCallback(
    (roles: any, userRole: any) => {
      if (!(roles || []).includes(userRole)) {
        navigate('/404');
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (route.path && !route.isPublic) {
      if (!user) {
        getCurrentUser()
          .then((resUser) => {
            checkRoles(route.roles, resUser.role);
            changeUser(resUser);
            if (resUser.isTemporaryPassword) {
              navigate('/admin/set-new-password');
            }
          })
          .catch(() => {
            removeToken();
            navigate('/login', {
              state: { referrer: window.location.pathname },
            });
          });
      } else {
        checkRoles(route.roles, user.role);
      }
    }
  }, [route, checkRoles, navigate, user]);

  const isAdmin = useMemo(() => {
    return [UserRole.ADMIN].includes(user?.role as UserRole);
  }, [user]);

  const isEditor = useMemo(() => {
    return [UserRole.ADMIN, UserRole.AGENCY_EDIT].includes(
      user?.role as UserRole,
    );
  }, [user]);

  const isBroker = useMemo(() => {
    return [UserRole.AGENCY_VIEW].includes(user?.role as UserRole);
  }, [user]);

  const isLeadsFlowEditor = useMemo(() => {
    return [UserRole.LEADS_FLOW_EDIT].includes(user?.role as UserRole);
  }, [user]);

  if (route.path && !route.isPublic && !user) {
    return (
      <div className={classes.loaderRoot}>
        <CircularProgress />
      </div>
    );
  }

  if (route.publicOnly && getToken()) {
    return <Navigate to="/admin" />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        isLogoutProcessing,
        isAdmin,
        isEditor,
        isBroker,
        isLeadsFlowEditor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
