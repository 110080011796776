import React, { ReactElement } from 'react';
import { Outlet } from 'react-router';
import PublicLayout from 'src/layouts/PublicLayout';

const VitecSendoutRoot = (): ReactElement => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default VitecSendoutRoot;
