import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const useSafeState = <T>(defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [data, changeData] = useState<T>(defaultValue);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onChange = useCallback(
    (newData: any) => {
      if (isMounted.current) {
        changeData(newData);
      }
    },
    [changeData],
  );

  return [data, onChange];
};

export default useSafeState;
