import { createTheme, adaptV4Theme } from '@mui/material/styles';
import palette from 'src/theme/adminTheme/palette';
import typography from 'src/theme/adminTheme/typography';
import overrides from 'src/theme/adminTheme/overrides';

const baseTheme = {
  palette,
  typography,
  overrides,
};

export default createTheme(adaptV4Theme(baseTheme as any));
