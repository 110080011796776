import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router';

const dynamicRoutes = [
  {
    path: '/dynamic/:hash/:page?/:roleKey?/:participantId?',
    Component: lazy(() => import('src/pages/PublicHandover')),
    isPublic: true,
    children: [
      {
        index: true,
        Component: lazy(() => import('src/routes/helpers/DynamicFormHelper')),
      },
      {
        Component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default dynamicRoutes;
