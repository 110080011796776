import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import Assessment from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import { UserRole } from 'src/types/user';

export type NavItem = {
  title: string;
  icon?: any;
  href?: string;
  items?: NavItem[];
  roles?: UserRole[];
};

export type NavConfig = {
  subheader?: string;
  items: NavItem[];
  roles?: UserRole[];
};

const config: NavConfig[] = [
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Dashboards',
        href: '/admin',
        icon: DashboardIcon,
        roles: [
          UserRole.ADMIN,
          UserRole.AGENCY_VIEW,
          UserRole.LEADS_FLOW_EDIT,
          UserRole.AGENCY_EDIT,
          UserRole.PROVIDER_EDIT,
        ],
      },
      {
        title: 'Handovers',
        href: '/admin/handovers',
        icon: DescriptionIcon,
        roles: [
          UserRole.ADMIN,
          UserRole.AGENCY_VIEW,
          UserRole.LEADS_FLOW_EDIT,
          UserRole.AGENCY_EDIT,
          UserRole.PROVIDER_EDIT,
        ],
      },
      {
        title: 'Leads flow',
        href: '/admin/leads-flow',
        icon: DescriptionIcon,
        roles: [
          UserRole.ADMIN,
          UserRole.AGENCY_VIEW,
          UserRole.LEADS_FLOW_EDIT,
          UserRole.AGENCY_EDIT,
          UserRole.PROVIDER_EDIT,
        ],
      },
      {
        title: 'Management',
        icon: BarChartIcon,
        items: [
          {
            title: 'Users',
            href: '/admin/users',
            roles: [
              UserRole.ADMIN,
              UserRole.AGENCY_EDIT,
              UserRole.PROVIDER_EDIT,
            ],
          },
          {
            title: 'Quicksight',
            href: '/admin/quicksight',
            roles: [UserRole.ADMIN],
          },
        ],
        roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
      },
      {
        title: 'Settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Agencies',
            href: '/admin/agencies',
            roles: [
              UserRole.ADMIN,
              UserRole.AGENCY_EDIT,
              UserRole.PROVIDER_EDIT,
            ],
          },
          {
            title: 'Handover Providers',
            href: '/admin/handoverProviders',
            roles: [
              UserRole.ADMIN,
              UserRole.AGENCY_EDIT,
              UserRole.PROVIDER_EDIT,
            ],
          },
          {
            title: 'Protocol Config',
            href: '/admin/protocol-config',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Settlement Config',
            href: '/admin/settlement-config',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'LeadsFlow Config',
            href: '/admin/leads-flow-config',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Service Providers',
            href: '/admin/service-providers',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Rates',
            href: '/admin/rates',
            roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT],
          },
          {
            title: 'Region Information',
            href: '/admin/region-info',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Pricing Areas',
            href: '/admin/pricing-area',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Countries',
            href: '/admin/countries',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Popular Categories',
            href: '/admin/popular-categories',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Participant Sub-Roles',
            href: '/admin/sub-roles',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'General',
            href: '/admin/general',
            roles: [UserRole.ADMIN],
          },
        ],
        roles: [UserRole.ADMIN, UserRole.AGENCY_EDIT, UserRole.PROVIDER_EDIT],
      },
      {
        title: 'Reports',
        icon: Assessment,
        items: [
          {
            title: 'Leads',
            href: '/admin/reports-leads',
            roles: [
              UserRole.ADMIN,
              UserRole.AGENCY_EDIT,
              UserRole.LEADS_FLOW_EDIT,
              UserRole.AGENCY_VIEW,
              UserRole.PROVIDER_EDIT,
            ],
          },
          {
            title: 'Logs',
            href: '/admin/reports-logs',
            roles: [
              UserRole.ADMIN,
              UserRole.AGENCY_EDIT,
              UserRole.AGENCY_VIEW,
              UserRole.PROVIDER_EDIT,
            ],
          },
          {
            title: 'Notifications',
            href: '/admin/notifications',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Handovers Sync',
            href: '/admin/reports-sync',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Handovers Sync Queue',
            href: '/admin/reports-sync-queue',
            roles: [UserRole.ADMIN],
          },
          {
            title: 'Lead Error Follow-ups',
            href: '/admin/reports-lead-error-follow-ups',
            roles: [UserRole.ADMIN],
          },
        ],
        roles: [
          UserRole.ADMIN,
          UserRole.AGENCY_VIEW,
          UserRole.LEADS_FLOW_EDIT,
          UserRole.AGENCY_EDIT,
          UserRole.PROVIDER_EDIT,
        ],
      },
    ],
    roles: [
      UserRole.ADMIN,
      UserRole.AGENCY_VIEW,
      UserRole.LEADS_FLOW_EDIT,
      UserRole.AGENCY_EDIT,
      UserRole.PROVIDER_EDIT,
    ],
  },
];

export default config;
