import palette from 'src/theme/adminTheme/palette';

export default {
  root: {
    '&.selected': {
      backgroundColor: palette.background.default,
    },
    '&.Mui-selected': {
      backgroundColor: palette.background.default,
    },
    '&.hover': {
      '&:hover': {
        backgroundColor: palette.background.default,
      },
    },
    '&.Mui-hover': {
      '&:hover': {
        backgroundColor: palette.background.default,
      },
    },
  },
};
