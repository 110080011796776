import { colors } from '@mui/material';

export const GetEnvironmentSpecificPalette = (
  environmentUrl: string,
): EnvironmentColors => {
  const result = environmentColorMap.find((x) =>
    environmentUrl.includes(x.environmentName),
  );

  if (result) return result;

  //Using localhost colors as default if no specific override is found.
  return {
    environmentName: 'localhost',
    primaryPalette: {
      dark: colors.red[900],
      main: colors.red[500],
      light: colors.red[100],
    },
    secondaryPalette: {
      dark: colors.red[900],
      main: colors.red.A700,
      light: colors.red.A400,
    },
  };
};

const environmentColorMap: EnvironmentColors[] = [
  {
    environmentName: 'dev.overo',
    primaryPalette: {
      dark: colors.green[900],
      main: colors.green[500],
      light: colors.green[100],
    },
    secondaryPalette: {
      dark: colors.green[900],
      main: colors.green.A700,
      light: colors.green.A400,
    },
  },
  {
    environmentName: 'dynamic-',
    primaryPalette: {
      dark: colors.green[900],
      main: colors.green[500],
      light: colors.green[100],
    },
    secondaryPalette: {
      dark: colors.green[900],
      main: colors.green.A700,
      light: colors.green.A400,
    },
  },
  {
    environmentName: 'demo.overo',
    primaryPalette: {
      dark: colors.pink[900],
      main: colors.pink[500],
      light: colors.pink[100],
    },
    secondaryPalette: {
      dark: colors.pink[900],
      main: colors.pink.A700,
      light: colors.pink.A400,
    },
  },
  {
    environmentName: 'app.overo',
    primaryPalette: {
      dark: colors.indigo[900],
      main: colors.indigo[500],
      light: colors.indigo[100],
    },
    secondaryPalette: {
      dark: colors.blue[900],
      main: colors.blue.A700,
      light: colors.blue.A400,
    },
  },
];

interface EnvironmentPalette {
  dark: string;
  main: string;
  light: string;
}

interface EnvironmentColors {
  environmentName: string;
  primaryPalette: EnvironmentPalette;
  secondaryPalette: EnvironmentPalette;
}
