import React, { ReactElement } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from 'src/theme/protocolTheme';
import ProtocolLayout from 'src/layouts/ProtocolLayout';
import { Outlet } from 'react-router';

const ProtocolRoot = (): ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <ProtocolLayout>
        <Outlet />
      </ProtocolLayout>
    </ThemeProvider>
  );
};

export default ProtocolRoot;
