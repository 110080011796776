import React, { ReactElement, Suspense } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import { LinearProgress } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    main: {
      width: '100%',
    },
  }),
);

const Layout = (props: any): ReactElement => {
  const { classes }: any = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.main}>
        <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
      </main>
    </div>
  );
};

export default Layout;
