import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router';

const settlementRoutes = [
  {
    path: '/settlement/:hash/:page?/:roleKey?/:participantId?',
    Component: lazy(() => import('src/pages/PublicHandover')),
    isPublic: true,
    children: [
      {
        index: true,
        isPublic: true,
        Component: lazy(() => import('src/routes/helpers/SettlementHelper')),
      },
      // {
      //   path: '/settlement/:hash/:page(done)',
      //   isPublic: true,
      //   Component: lazy(() =>
      //     import('src/pages/PublicHandover/SettlementDone'),
      //   ),
      // },
      // {
      //   path: `/settlement/:hash/:page(${SettlementQuestionName.SELLER_PARTICIPANTS}|${SettlementQuestionName.BUYER_PARTICIPANTS})/:participantId?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/settlement/:hash/:page(${SettlementQuestionName.LOANS})/:number?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/settlement/:hash/:page(${SettlementQuestionName.SELLER_BANK_ACCOUNTS})/:number?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/settlement/:hash/:page(${SettlementQuestionName.SIGNATURE})/:roleKey?/:participantId?`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      // {
      //   path: `/settlement/:hash/:page(init|${Object.values(
      //     SettlementQuestionName,
      //   ).join('|')})`,
      //   isPublic: true,
      //   Component: lazy(() => import('src/pages/PublicHandover/Page')),
      // },
      {
        Component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default settlementRoutes;
