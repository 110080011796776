import React, { useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Theme,
} from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from 'src/pages/AuthProvider';
import LogoImage from 'src/assets/logo.png';
import { getProtocolTranslation } from 'src/services/api/locales';
import { PROTOCOL_NAMESPACE } from 'src/i18n';
import { useTranslation } from 'react-i18next';
import SearchBar from 'src/components/SearchBar';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  colored: {
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    height: '25px',
  },
}));

interface Props {
  toggleMenu: () => void;
}

const TopBar: React.FunctionComponent<Props> = ({ toggleMenu }) => {
  const { classes } = useStyles();
  const { logout, isLogoutProcessing, user } = useContext(AuthContext);
  const { i18n } = useTranslation();
  useEffect(() => {
    const locale = user?.agencies?.[0]?.country?.defaultLanguage || 'no';
    getProtocolTranslation(locale, true).then((data) => {
      i18n.addResourceBundle(locale, PROTOCOL_NAMESPACE, data);
      i18n.changeLanguage(locale);
    });
  }, [i18n]);

  const onSearch = (value: { [key: string]: string }) => {
    const resultLink =
      '/admin/handovers?page=0&countPerPage=30&address=&assignNumber=&orderBy=date&orderDirection=asc&agency=&startDate=01-01-2020&endDate=12-12-2043&onlyIncorrect=false&showNewProperty=true&fullSearch=' +
      value['address'];
    window.location.href = resultLink;
  };

  return (
    <AppBar className={classes.root} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/admin">
          <img alt="Logo" src={LogoImage} className={classes.logo} />
        </RouterLink>
        <div className={classes.flexGrow}>
          <SearchBar onSearch={onSearch} color="white" />
        </div>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={logout}
          disabled={isLogoutProcessing}
        >
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
