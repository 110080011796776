import ApiService from 'src/services/api/ApiService';
import { ConfigType } from 'src/types/handover';
const api = ApiService.getInstance();

export const list = async (
  configType: ConfigType,
  configId: string,
  notificationChannelKey: string,
) => {
  return await api.post(`/locales/notification-texts`, {
    notificationChannelKey,
    configType,
    configId,
  });
};

export const updateAll = async (
  texts: { key: string; value: string; language: string }[],
  configType: ConfigType,
  configId: string,
) => {
  return await api.put(`/locales/notification-texts`, {
    texts,
    configType,
    configId,
  });
};

export const getDynamicTranslation = async (language: string) => {
  const url = `/locales/dynamic/${language}`;
  return await api.get(url);
};

export const getProtocolTranslation = async (
  language: string,
  extended = false,
  agencyId?: string,
) => {
  let url = `/locales/protocol/${language}`;
  if (extended) {
    url += '/extended/';
    if (agencyId) {
      url += agencyId;
    }
  }
  return await api.get(url);
};

export const getProtocolTranslationByKey = async (key: string) => {
  return await api.get(`/locales/by-key-name/protocol/${key}`);
};

export const getSettlementTranslation = async (language: string) => {
  return await api.get(`/locales/settlement/${language}`);
};
