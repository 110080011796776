import { colors } from '@mui/material';
import { GetEnvironmentSpecificPalette } from 'src/theme/adminTheme/environmentColorResolver';

const environmentUrl = window.location.href;
const { primaryPalette, secondaryPalette } =
  GetEnvironmentSpecificPalette(environmentUrl);
const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: primaryPalette.dark,
    main: primaryPalette.main,
    light: primaryPalette.light,
  },
  secondary: {
    contrastText: white,
    dark: secondaryPalette.dark,
    main: secondaryPalette.main,
    light: secondaryPalette.light,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
};
