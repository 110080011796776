import { useCallback, useEffect } from 'react';
import { toast as showToast } from 'react-toastify';
import ValidationError from 'src/services/api/errors/ValidationError';
import useSafeState from 'src/hooks/useSafeState';
import ErrorToastWithButton from 'src/components/ErrorToastWithButton';

interface ReturnType<T> {
  execute: (...props: any[]) => Promise<any>;
  data: T | null;
  isLoading: boolean;
  error: any;
  clearError: () => void;
  clearData: () => void;
}

interface Props<T> {
  fn: (...props: any[]) => Promise<T>;
  initialData?: T | null;
  initialError?: any;
  immediate?: boolean;
  immediateParams?: { [key: string]: any } | any;
  options?: {
    successCb?: (...props: any[]) => any;
    failedCb?: (...props: any[]) => any;
    toast?: {
      useToast?: boolean;
      successMessage?: string;
      errorMessage?: string;
    };
  };
  deps?: any[];
}

const useAsync = <T>(props: Props<T>): ReturnType<T> => {
  const [data, changeData] = useSafeState<T | null>(props.initialData || null);
  const [isLoading, changeIsLoading] = useSafeState<boolean>(false);
  const [error, changeError] = useSafeState<any>(props.initialError || null);

  const clearError = useCallback(() => {
    changeError(props.initialError || null);
  }, [props.initialError, changeError]);

  const clearData = useCallback(
    () => changeData(props.initialData || null),
    [props.initialData],
  );

  const execute = useCallback(
    async (...exProps: any) => {
      changeIsLoading(true);
      changeData(props.initialData || null);
      changeError(props.initialError || null);

      return new Promise((resolve, reject) =>
        props
          .fn(...exProps)
          .then((response) => {
            changeData(response);
            if (props?.options?.successCb) {
              props.options.successCb(response);
            }
            if (
              props?.options?.toast?.useToast &&
              props?.options?.toast?.successMessage
            ) {
              showToast.success(
                props.options.toast.successMessage || 'Success',
              );
            }
            resolve(response);
          })
          .catch((err) => {
            const error = err instanceof ValidationError ? err.errors : err;
            changeError(error);
            if (props?.options?.failedCb) {
              props.options.failedCb(err.message);
            }

            if (props?.options?.toast?.useToast) {
              showToast.error(
                ErrorToastWithButton({
                  message: props?.options?.toast?.errorMessage || err?.message,
                  requestId: err.requestId,
                }),
                {
                  autoClose: false,
                },
              );
            }
            reject(err.errors || err.message);
          })
          .finally(() => changeIsLoading(false)),
      );
    },
    [props.fn, props?.options?.successCb, props?.options?.failedCb],
  );

  useEffect(() => {
    if (props.immediate) {
      execute(props.immediateParams).catch((error) => {
        console.log(error);
      });
    }
  }, [execute, props.immediate, ...(props.deps || [])]);

  return {
    execute,
    data,
    isLoading,
    error,
    clearError,
    clearData,
  };
};

export default useAsync;
