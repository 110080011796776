import React from 'react';
import { Outlet } from 'react-router-dom';
import PublicLayout from 'src/layouts/PublicLayout';

const PublicRoot = () => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoot;
