import React, { ReactElement } from 'react';
import { Outlet } from 'react-router';
import DashboardLayout from 'src/layouts/DashboardLayout';

const AdminRoot = (): ReactElement => {
  return (
    //@ts-ignore
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default AdminRoot;
