import { Agency, HandoverProvider } from 'src/types/agency';

export enum UserRole {
  ADMIN = 'admin',
  AGENCY_EDIT = 'agencyEdit',
  PROVIDER_EDIT = 'providerEdit',
  LEADS_FLOW_EDIT = 'leadsFlowEdit',
  AGENCY_VIEW = 'agencyView',
  REST_API_USER = 'restApiUser',
}

export interface User {
  id: string;
  isTemporaryPassword: boolean;
  email: string;
  password: string;
  role: UserRole;
  isActive: boolean;
  phoneNumber?: string;
  fullName?: string;
  agencies?: Agency[];
  handoverProviders?: HandoverProvider[];
  notificationEmails: string[] | null;
  parent?: User;
  createdAt?: string;
}
