export type Errors = {
  [key: string]: any;
};

export default class ValidationError extends Error {
  constructor(public errors: Errors, public requestId?: string) {
    super('Validation Error');
    this.name = 'Validation Error';
  }
}
